.switch-frame{
    /* border: 5px solid tomato; */
    border-radius: 5px;
    position: absolute;
    right:20px;
    top:100px;
    padding: 1px 2px;
    /* -webkit-box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 0px 0px 3px 5px rgba(0,0,0,0);  */
    /* box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 0px 0px 3px 5px rgba(0,0,0,0); */
    transition-duration: 2s;
    background-color: transparent;
}
.night-frame{
    /* border: 5px solid gray; */

}
.switch-box{
    height: 100px;
    width: 50px;
    border-radius: 6px;
    background-image: url("./switch_on.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: rotate3d(1, 0, 0, 20deg);
    /* background-color: red; */
}
.button-background{
    position: absolute;
    display: block;
    height: 80%;
    width: 50%;
    margin: auto ;
    top: 10%;
    left: 25%;
    background-color: red;
    z-index: -0;
}
.click-on-day{
    animation-duration: 0.5s;
    animation-name: turn-on;
    transform: rotate3d(1, 0, 0, 20deg)
}
@keyframes turn-on{
    50% {transform: rotate3d(1, 0, 0, 30deg);}
}
.click-on-night{
    animation-duration: 0.5s;
    animation-name: turn-off;
    transform: rotate3d(1, 0, 0, 0deg)
}
@keyframes turn-off{
    50% {transform: rotate3d(1, 0, 0, -10deg);}
}
.switch{
    width: 100%;
    height: 50%;
    border: none;
    background: transparent;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .switch-frame{
        right:20px;
        top:30px;
        padding: 1px 1px 1px 2px;
        -webkit-box-shadow: 5px 5px 5px 0px #000000, inset 2px 4px 15px 0px #000000, 0px 0px 3px 5px rgba(0,0,0,0); 
        box-shadow: 5px 5px 5px 0px #000000, inset 2px 4px 15px 0px #000000, 0px 0px 3px 5px rgba(0,0,0,0);
    
    }
    .switch-box{

        height: 65px;
        width: 33px;
    }
}