
/* //////////////  Home content /////////////// */
.home{

    position:relative;
    background-attachment: fixed;
    /* background-size: 100%;
    background-position: center;
    background-repeat: no-repeat; */
    background-image: url(./media/City.png);
    height: 100%;
    margin-top: 0px;
}

.Home_Title{
    font-size: 45pt;
    position: relative;
    color: #fbe35d;
    max-width: 40%;
    margin-left:20%;
    transform: translateY(200px);
    line-height: 1.3;
    /* background-color: rgba(25,23,21,0.5); */
    text-shadow: 1px 1px 2px rgba(25,23,21,1), 0 0 1em rgba(25,23,21,1), 0 0 0.2em rgba(25,23,21,1);

    /* text-shadow: rgba(25,23,21,1) 1px 0 10px; */

}





@media only screen and (max-width: 1366px) {



}


@media only screen and (max-width: 1044px) {



}



@media only screen and (max-width: 800px) {


}


@media only screen and (max-width: 600px) {
    .Home_Title{
        font-size: 35pt;
        /* position: relative; */
        color: #fbe35d;
        max-width: 95%;
        margin-left:10px;
        transform: translateY(200px);
        line-height: 1.3;
        /* background-color: rgba(25,23,21,0.5); */
        text-shadow: 1px 1px 2px rgba(25,23,21,1), 0 0 1em rgba(25,23,21,1), 0 0 0.2em rgba(25,23,21,1);
    
        /* text-shadow: rgba(25,23,21,1) 1px 0 10px; */
    
    }

}
/* //////////////  /Home content /////////////// */
