.footer{
    position: relative;
    display: block;
    margin-top: 60px;
    background-color: black;
    /* background-color: #272727; */
    padding:10px;
    max-height: 500px;
    color: #fbe35d;
    padding-top: 50px;

}

.flex-box{
    position: relative;
    display: flex;
    justify-content: space-between;
}

.copyright{
    color: white;
}


@media only screen and (max-width: 1366px) {

    .footer .contact-form{
        max-width: 400px;

    }

    .footer  .form-inputs{
        width: 90%;
        padding: 7px 5px 0px 10px;
        font-size: 10pt;
    }

}


@media only screen and (max-width: 1044px) {

    .flex-box{
        justify-content: space-around;
    }

    .footer .contact-form{
        display: none;
    }


}



@media only screen and (max-width: 800px) {


}


@media only screen and (max-width: 600px) {
    .copyright{
        display: block;
        margin-top: 50px;
    }
}
