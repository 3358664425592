.our-projects{
    background-color: inherit;
}


.hex img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}
  
.hexagon-gallery {
    margin: auto;
    margin-top: 50px;
    max-width: 1500px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 200px;
    grid-gap: 14px;
    padding-top: 50px;
    padding-bottom: 100px;

    /* background-color: cornflowerblue; */

}

.hex {
    display: flex;
    position: relative;
    width: 240px;
    height: 265px;
    background-color: #424242;
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    color: aliceblue;
}

.hex:first-child {
    grid-row-start: 1;
    grid-column: 2 / span 2;
}

.hex:nth-child(2) {
    grid-row-start: 1;
    grid-column: 4 / span 2;
}

.hex:nth-child(3) {
    grid-row-start: 1;
    grid-column: 6 / span 2;
}

.hex:nth-child(4) {
    grid-row-start: 1;
    grid-column: 8 / span 2;
}

.hex:nth-child(5) {
    grid-row-start: 1;
    grid-column: 10 / span 2;
}









.hex:nth-child(6) {
    grid-row-start: 2;
    grid-column: 3 / span 2;
}

.hex:nth-child(7) {
    grid-row-start: 2;
    grid-column: 5 / span 2;
}

.hex:nth-child(8) {
    grid-row-start: 2;
    grid-column: 7 / span 2;
}

.hex:nth-child(9) {
    grid-row-start: 2;
    grid-column: 9 / span 2;
}






.hex:nth-child(10) {
    grid-row-start: 3;
    grid-column: 2 / span 2;
}

.hex:nth-child(11) {
    grid-row-start: 3;
    grid-column: 4 / span 2;
}
.hex:nth-child(12) {
    grid-row-start: 3;
    grid-column: 6 / span 2;
}
.hex:nth-child(13) {
    grid-row-start: 3;
    grid-column: 8 / span 2;
}
.hex:nth-child(14) {
    grid-row-start: 3;
    grid-column: 10 / span 2;
}
