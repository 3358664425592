.navigation{
    /* background-color: blue; */
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    width: 60%;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
}
.navItem{

}

.navItem a{
    display: block;
    text-decoration: none;
    color: inherit;
    height: 100%;
    line-height: 2;
    cursor: pointer;

}


@media only screen and (max-width: 600px) {

    .navigation{
        position: absolute;
        /* background-color: blue; */
        justify-content: space-around;
        width: 80vw;
        margin-top: 10%;
        color: #fbe35d;
        /* color: #272727; */

    }

    .navItem a{
        padding-left: 15px;
        padding-right: 15px;
        border:0.5px solid tomato;
        border-radius: 50px;
        background-color: #272727;
        /* background-color: #fbe35d; */

    }

}