.notFound{
    position: fixed;
    top: 0px;
    height: 100vh;
    width: 100%;
    /* background-color: #fbe35d; */
    z-index: 999;
}
.centered{
    width: 100%;


}
.linkTo{
    display: block;
    height: 40px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    background-color: #272727;
    color: #fbe35d;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    line-height: 2.4;
}

.image404{
    max-width: 600px;
    height: auto;
    position: relative;
    display: block;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 100px
};


@media only screen and (max-width: 600px) {

    .image404{
        max-width: 600px;
        height: auto;
        position: absolute;
        display: initial;

    };

} 