
.contacts{
    position:relative;
    background-attachment: fixed;
    background-size: 100vw;
    /* background-position-y: 60%; */
    background-repeat: no-repeat;
    background-image: url(./media/exterior_design.png);
    /* height: 100%; */
    margin-top: 0px;

}

.contacts-container{
    padding-top: 100px;
    display: flex;
    justify-content: space-evenly;
    background-color: #fbe35d;


    margin-top: 100vh;

}

.night{
    background-color: #272727;
    color: #fbe35d;
  
}

.contacts-section{
    max-width: 700px;
    
}
.contacts-title{
    font-size: 39pt;
    font-weight: lighter;
}

.contacts-title{
    font-size: 45pt;
    position: relative;
    color: #fbe35d;
    max-width: 40%;
    /* margin-left:20%; */
    transform: translateY(200px);
    line-height: 1.3;
    padding-left: 50px;
    background-color: rgba(25,23,21,0.8);
    text-shadow: 2px 2px 4px rgba(25,23,21,1), 0 0 1em rgba(25,23,21,1), 0 0 0.2em rgba(25,23,21,1);

    /* text-shadow: rgba(25,23,21,1) 1px 0 10px; */

}

.contacts-description{
    font-size: 16pt;
    font-weight:lighter;
    text-align: justify;
    line-height: 2;


}
.contacts-section span, .contacts-section a, .contacts-section a:visited{
    text-decoration: none;
}

.mobile-centered{
    color: inherit;
}

.text-whatsapp-button{
    display: block;
    height: 40px;
    width: 100%;
    max-width: 200px;
    font-size: larger;
    color:black;
    line-height:2;
    text-align: center;
    text-decoration: none;
    /* margin-left: auto;
    margin-right: auto; */
    background-color: #25d366;
    border-radius: 50px;
}
.mail-address{
    /* background-color: #25d366; */
    display: block;
    font-size: larger;

}
.second-column{
    /* border: solid black; */
}
.button-fade{
    margin-top: 20px;
}

.contacts-image-size1{
    display: block;
    margin-top: 0px;
    max-height: 400px;
    border-radius: 30% 70% 73% 27% / 47% 67% 33% 53% ;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}






@media only screen and (max-width: 1600px) {

}

/* 
@media only screen and (max-width: 1366px) {

} */


@media only screen and (max-width: 1366px) and (min-width: 901px){
    .contacts-section{
        padding-left: 2%;
        position: relative;

        margin-bottom: 500px;
    }
    .contacts-description{
        /* margin-left: 2%; */
        margin-top: 0px;
        width: 100%;
    }

    .mobile-centered{
        text-align: center;
    }
    .phone-number{
        display: block;
    }

    .text-whatsapp-button{
        margin-left: auto;
        margin-right: auto;
    }


    .second-column{
        margin-left: 6%;
        margin-right: 0.5%;
        margin-top:100 ;
        margin-bottom: 500px;
    }

    .contacts-image-size1{
        max-height: 340px;
    }
 
    .contacts-section .mapContainer{
        position: absolute;
        display: block;
        bottom: -500px;
        margin-left: auto;
        margin-right: auto;
        width: 95vw;
        max-width: inherit;
        height: 500px;
    }
}



@media only screen and (max-width: 900px) {

    .contacts{
        position:relative;
        background-attachment: fixed;
        background-size: 100vw;
        background-position: center;
    
        background-position-y:inherit;
        background-repeat: no-repeat;
        background-image: url(./media/exterior_design3.png);
        overflow: hidden;
    }

    .contacts-container{
        padding-top: 0px;
        margin-top: 0px;
        display: block;
        /* margin-top: 100vh; */
    
    }

    .contacts-section{
        max-width: 95%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 200px;
    }
    .contacts-description{
        padding-top: 50px;
        /* background-color: #25d366; */
        margin-left: auto;
        margin-right: auto;
    }
    .contacts-title{
        position: relative;
        display: block;
        font-size: 39pt;
        max-width: 80%;
        transform: translateY(0px);
        margin-left: auto;
        margin-right: auto;
        margin-top: 75%;
        line-height: 1.3;
        text-align: center;
        padding: 0px;
        background-color: transparent;
        text-shadow: 2px 2px 4px rgba(25,23,21,1), 0 0 1em rgba(25,23,21,1), 0 0 0.2em rgba(25,23,21,1);
    }
    .mobile-centered{
        width: 100%;
        display: block;
        text-align: center;
    }

    .text-whatsapp-button{
        margin-left: auto;
        margin-right: auto;
    }
    .contacts .mobile{
        display: initial;
    }

    .contacts .screen{
        display: none;
    }
    .contacts-image-size1{
        max-height: 340px;
        margin-top: 50px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .contact-page-form{
        margin: auto;
    }

}

@media only screen and (max-width: 800px) {

    .contacts-image-size1{
        max-height: 300px;
    }

}

@media only screen and (max-width: 600px) {

    .contacts-image-size1{
        max-height: 280px;

    }

}