
.homeContent{
    padding-top: 1px;
    padding-bottom: 200px;
    display: block;
    background-color: #fbe35d;
    margin-top: 100vh;
}
.night{
    background-color: #272727;
    color: #fbe35d;
  
  }

.homeSection{
    max-width: 80%;
    margin-top: 200px;
    /* border: solid black; */
    display: flex;
    justify-content:space-between;

    margin-left: auto;
    margin-right: auto;
}

.home-image-size1{
    max-height: 500px;
    border-radius: 30% 70% 73% 27% / 47% 67% 33% 53% ;
}

.home-section-info{
    width: 50%;
    height: 100%;
    margin: initial;
    margin-top: auto;
    margin-bottom: auto;
    /* background-color: darkolivegreen; */
}

.home-section-title{
    text-align: center;
}

.home-section-description{
    font-size: 14pt;
    line-height: 2;
}

.more-btn{
  display: none;
    
}



@media only screen and (max-width: 1600px) {

    .homeSection{
        max-width: 85%;

    }

}


@media only screen and (max-width: 1366px) {

    .homeSection{
        max-width: 90%;
        /* background-color: hsl(25, 75%, 47%); */
        justify-content:space-between
       
    }

    .home-image-size1{
        max-height: 400px;
    }
    .home-section-info{
        margin: 0px;
        /* background-color: aqua; */

    }


}


@media only screen and (max-width: 1044px) {

    .homeSection{
        max-width: 98%;
       
    }

    .home-section-title{
        margin-top: 0%;
    }


    .home-section-info{
        width: 95%;
        /* background-color: aqua; */
        /* text-align: justify; */
    }

    .home-section-description{
        font-size: 14pt;
        line-height: 2;
        width: 95%;
        margin-left: auto;
        margin-right: auto;


    }

}

@media only screen and (max-width: 900px) {
    .home-image-size1{
        max-height: 340px;
    }

    .home-section-description{

        width: 90%;
    }
}


@media only screen and (max-width: 800px) {

    .homeSection{
        max-width: 98%;
        display: block;
        margin-top: 100px;

    }

    .home-section-title{
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;

    }
    .home-image-size1{
        max-height: 340px;
        margin-top: 50px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
    }

    .home-section-info{
        width: 95%;
        height: 100%;
        margin:auto;
    
    }
    .home-section-description{
        line-height: 3.5ex;
    }

    .show-les{
        height: 10.5ex;
        overflow: hidden;
    }

    .show-more{
        height: inherit;
    }



    .more-btn{
        position: relative;
        display: inherit;
        height: 1ex;
        width: 100%;
        line-height: 2ex;
        /* background-color: #fbe35d; */
        background-color: transparent;
        border: none;
        font-size: 30pt;
        font-weight: 250;
        cursor: pointer;
        padding: 0px;

        -webkit-tap-highlight-color: transparent;
    }


    .more-btn-show-les{
        border: none;
        border-top: 1px solid black;

    }
    .more-btn-show-more{
        border: none;
        border-bottom: 1px solid black;
        line-height: 1ex;

    }
    .screen{
        display: none;
    }
}

@media only screen and (max-width: 600px) {

    .home-image-size1{
        max-height: 280px;
    }

}

@media (hover: hover) and (pointer: fine) {
    .more-btn :hover { color: red; }
  }
  