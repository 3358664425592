.footer-social{
    max-width: 350px;
    /* border: solid blueviolet; */
    position: relative;
    /* background-color: aqua; */
}

.footer-social h3{
    position: relative;
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.social-container{
    position: relative;
    margin-top: 20%;
    height: 100px;
    width: 300px;
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.social-buttons{
    height: 45px;
    width: 45px;
    border: none;
    background-color: transparent;
    background-position: center;
    background-size: 100%;
    cursor: pointer;
    /* margin-left: 20px;
    margin-right: 20px; */
    
}

.facebook{
    background-image: url(./icons/facebook.png);
    top:5%;
    left:5%;
}

.whatsapp{
    background-image: url(./icons/whatsapp.png);
    top:5%;
    right:5%;
}
.a{
    background-color: blue;
    height: 100%;
    width: 100%;
    border: solid blue;
}

.instagram{
    background-image: url(./icons/instagram.png);
    bottom:5%;
    right:5%;
}

.telegram{
    background-image: url(./icons/telegram.png);
    bottom:5%;
    left: 5%;
}

@media only screen and (max-width: 1366px) {
    
    .social-container{
        justify-content: space-evenly;
    }

    .social-buttons{
        height: 40px;
        width: 40px;
    
    }
}


@media only screen and (max-width: 1044px) {


}


@media only screen and (max-width: 600px) {
    .footer-social{
        margin-left: auto;
        margin-right: auto;
    }
    .footer-social .mobile{
        margin-top: 0%;
        display: block;

    }
}