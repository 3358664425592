.mapContainer{
    position: relative;
    max-width: 700px;
    height: 450px;
    margin-left: auto;
    margin-right: auto;
  
}

.gmnoprint{
    /* display: none; */
}
.gm-svpc{
    display: none;
}