/* /////////////// Header /////////////// */

.header{
    position: relative;
    /* background-color:rgba(9, 10, 10, 0.561); */
    /* background-color: #FA7D09; */
    z-index: 100;
    max-height: 200px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.top{
    /* background-color: blue; */
    display: flex;
    justify-content: space-between;
    padding:0px 20px;
}
.LogoLink{
    text-decoration: none;
    color: initial;

}
 

.LogoLinknight{
    text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
    color: #fff6a9;
    animation: blink 12s infinite;
    -webkit-animation: blink 12s infinite;
}


@-webkit-keyframes blink {
    20%,
    24%,
    55% {
        color: #111;
        text-shadow: none;
    }
  
    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
        text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
        color: #fff6a9;
    }
  }
  
  @keyframes blink {
    20%,
    24%,
    55% {
        color: #111;
        text-shadow: none;
    }
  
    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
        text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
        color: #fff6a9;
    }
  }
  

.title{
    transition-duration:2s ;
    /* padding-top: 0px;
    line-height: 0.6; */
}

.LogoLinknight  .title{
    transition-duration:initial;
    /* padding-top: 0px;
    line-height: 0.6; */
}


.tomato{
    color: tomato;
}

.call-us-fade{
    position: fixed;
    width: 50px;
    height:50px;
    padding: 1px;
    right:20px;
    top:270px;
    border-radius: 50%;
    overflow: hidden;
}
.call-us-buttn{
    display: block;
    width: 50px;
    height:50px;
    border-radius: 50%;
    background-position: center;
    background-size: 100%;
    cursor: pointer;
    /* transform:rotate(-100deg); */
    -webkit-box-shadow: inset 0px 0px 15px 5px rgba(0,0,0,0.5); 
    box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.5);
}


@media only screen and (max-width: 600px) {

    .header{
        /* background-color: blue; */
        margin: 0px;
        position: relative;
        display: block;
        margin-bottom: 50px;
        padding-top: 1px;
    }
    .top{
        display:block;
    }
    title{
        display: contents;
        padding-top: 0px;

    }
    .contact-phone{
        font-size: 14pt;
        line-height: 0;
    } 
 

    .call-us-fade{
        right:10px;
        top:40vh;
    }


}

/* //////////////  /Header /////////////// */
