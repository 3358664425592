.services{
    font-size: 16pt;
    /* border: 1px solid #fbe35d; */
    padding-right: 20px;
    margin-bottom: 20px;
}

.services-list {
    text-decoration: none;
    list-style: none;
    padding-left: 20px;
}

.services-list h3{
    padding-left:20px ;
}
.services-list li{
   

}
.services-list a{
    text-decoration: none;
    color: #fbe35d;
    line-height: 1.5;
    

}

@media only screen and (max-width: 800px) {

    .services{
        display: none;
    }
}