.App{
  position: relative;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  transition-duration: 2s;
  /* background-color: #ffe65e; */
  background-color: #fbe35d;
  color: black;
  font-family: Fedra,Tahoma,sans-serif;
  /* padding-bottom: 60px;
   */
}

.night{
  /* background-color: black; */
  background-color: #272727;
  /* color: #424d56; */
  color: #fbe35d;

}

.day{
  background-color: #fbe35d;
  color: black;
}


.pages{
  background-color: #fbe35d;
  min-height: 100vh;
  transition-duration: 2s;
  overflow: hidden;


}
.mobile{
  display: none;
}

button,
a,
input,
select{
  -webkit-tap-highlight-color: transparent;
}

button:active,
a:active{
  transform: scale(0.9);
}

@media only screen and (max-width: 600px) {
  .screen{
    display: none;
  }

  /* .mobile{
    display: initial;
  } */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {

  }

}

