.company{
    padding-top: 150px;
}
.company-sections{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
.company-title{
    font-size: 39pt;
    font-weight: lighter;
}

.company-description{
    display: block;
    max-width: 600px;
    margin-top: 100px;    
    font-size: 16pt;
    font-weight: lighter;
    line-height: 2;

}
@media only screen and (max-width: 900px) {

    .subtitle{
        display: block;
        font-size: 18pt;
        line-height: 4;
        padding-top: 10px;
    }
}
@media only screen and (max-width: 600px) {
    .company-title{
        font-size: 35pt;
    }

    .subtitle{
        font-size: 16pt;
        line-height: 1;
    }
}